import { render, staticRenderFns } from "./MyNav.vue?vue&type=template&id=cd5b15e0&scoped=true&"
import script from "./MyNav.vue?vue&type=script&lang=js&"
export * from "./MyNav.vue?vue&type=script&lang=js&"
import style0 from "./MyNav.vue?vue&type=style&index=0&id=cd5b15e0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd5b15e0",
  null
  
)

export default component.exports